//
// Menu Base
//

// Menu group
.menu-group {
    display: flex;
}

// Menu & wrapper
.menu,
.menu-wrapper {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
}

// Sub inner
.menu-inner {
    padding: 0;
    margin: 0;
    list-style: none;
}

// Sub menu
.menu-sub {
    display: none;
    padding: 0;
    margin: 0;
    list-style: none;
    flex-direction: column;
}

// Menu item
.menu-item {
    display: block;
    padding: $menu-item-padding-y $menu-item-padding-x;

    // Menu Link
    .menu-link {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0;
        flex: 0 0 100%;
        padding: $menu-link-padding-y $menu-link-padding-x;
        transition: none;
        outline: none !important;
        font-weight: 500;

        .menu-icon {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: $menu-icon-size;
            margin-right: $menu-icon-space;

            .svg-icon {
                line-height: 1;
            }
        }

        .menu-bullet {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: $menu-bullet-size;
            margin-right: $menu-bullet-space;
        }

        .menu-title {
            display: flex;
            align-items: center;
            flex-grow: 1;
        }

        .menu-badge {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            margin-left: $menu-badge-space;
        }

        .menu-arrow {
            display: flex;
            align-items: stretch;
            position: relative;
            overflow: hidden;
            flex-shrink: 0;
            margin-left: $menu-arrow-space;
            width: $menu-arrow-size;
            height: $menu-arrow-size;

            &:after {
                display: block;
                width: 100%;
                content: " ";
                will-change: transform;
                background-size: 100% 100%;
                @include svg-bg-icon(arrow-start, var(--kt-text-muted));
                /*rtl:begin:remove*/
                @include svg-bg-icon(arrow-end, var(--kt-text-muted));
                /*rtl:end:remove*/
            }
        }
    }

    // Menu Content
    .menu-content {
        padding: $menu-link-padding-y $menu-link-padding-x;
    }
}

// Accordion arrows
.menu-item {
    &.show {
        .menu-link {
            .menu-arrow:after {
                backface-visibility: hidden;
                transition: $menu-accordion-arrow-transition;
            }
        }
    }
}

// Center alignment
.menu-center {
    justify-content: center;
}

// Heading
.menu-heading {
    color: var(--kt-menu-heading-color);
}

// Responsive
@each $direction in (up, down) {
    @each $breakpoint in map-keys($grid-breakpoints) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @if $infix and $direction == down {
            $infix: $infix + "-" + $direction;
        }

        @include media-breakpoint-direction($direction, $breakpoint) {
            // Accordion arrow
            .menu-item.menu#{$infix}-accordion {
                .menu-arrow:after {
                    @include menu-link-arrow-rotate(90deg, -90deg);
                }

                &.showing:not(.menu-dropdown),
                &.show:not(.hiding):not(.menu-dropdown) {
                    > .menu-link {
                        .menu-arrow:after {
                            @include menu-link-arrow-rotate(-90deg, 90deg);
                        }
                    }
                }
            }

            // Sub dropdown
            .menu-sub#{$infix}-dropdown {
                display: none;
                border-radius: $menu-dropdown-border-radius;
                background-color: var(--kt-menu-dropdown-bg-color);
                box-shadow: var(--kt-menu-dropdown-box-shadow);
                z-index: $menu-dropdown-z-index;

                // Dropdown show
                .show.menu-dropdown > &,
                &.menu.show,
                &.show[data-popper-placement] {
                    display: flex;
                    will-change: transform;

                    // Animation
                    @if ($menu-dropdown-animation == true) {
                        // Move up
                        animation: menu-sub-dropdown-animation-fade-in #{$menu-dropdown-animation-speed} ease 1, menu-sub-dropdown-animation-move-up #{$menu-dropdown-animation-speed} ease 1;
                    }
                }
            }

            // Sub accordion
            .menu-sub#{$infix}-accordion {
                display: none;

                .show:not(.menu-dropdown) > &,
                &.show {
                    display: flex;
                }
            }

            // Sub indention
            .menu-sub#{$infix}-indention {
                @include menu-sub-indention($menu-accordion-indention);

                // Active link
                .menu-item .menu-item .menu-link.active {
                    margin-right: $menu-link-padding-x;
                }
            }

            // Inline
            .menu#{$infix}-inline {
                display: flex;
            }

            // Reset link left & right paddings of level 1 menu links
            .menu#{$infix}-fit {
                > .menu-item {
                    > .menu-content,
                    > .menu-link {
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                    }
                }
            }

            .menu#{$infix}-column {
                flex-direction: column;
                width: 100%;
            }

            .menu#{$infix}-row {
                flex-direction: row;

                > .menu-item {
                    display: flex;
                    align-items: center;

                    > .menu-link {
                        .menu-arrow:after {
                            @include menu-link-arrow-rotate(90deg, -90deg);
                        }
                    }
                }
            }

            // Border radiuses
            .menu#{$infix}-rounded {
                .menu-link {
                    @include border-radius($menu-link-border-radius);
                }
            }

            // Border radiuses
            .menu#{$infix}-pill {
                .menu-link {
                    border-radius: 50px;
                }
            }

            // Reset border radiuses
            .menu#{$infix}-rounded-0 {
                .menu-link {
                    border-radius: 0 !important;
                }
            }
        }
    }
}

// Link indention
.menu-link-indention {
    .menu-item {
        padding-top: 0;
        padding-bottom: 0;
    }

    @include menu-link-indention($menu-accordion-indention, $menu-link-padding-x);
}

// Menu animations
@keyframes menu-sub-dropdown-animation-fade-in {
    from   {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes menu-sub-dropdown-animation-move-up {
    from   {
        margin-top: #{$menu-dropdown-animation-move-offset};
    }

    to {
        margin-top: 0;
    }
}

@keyframes menu-sub-dropdown-animation-move-down {
    from   {
        margin-bottom: #{$menu-dropdown-animation-move-offset};
    }

    to {
        margin-bottom: 0;
    }
}