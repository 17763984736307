
.aside-menu .menu .menu-item .menu-link {
    width: 100%;
}

.aside-menu .menu .menu-item.here .menu-link {
    background-color: #B0C4DE;
}

.aside-menu .menu .menu-item:hover .menu-link .menu-icon i {
    color: #FFFFFF;
}

.aside-menu .menu .menu-item .menu-link .menu-icon i {
    color: #9899ac;
}

.aside-menu .menu .menu-item.show .menu-link .menu-icon i {
    color: #FFFFFF !important;
}